var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }

      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailSuccess = $('.email-signup__success', $emailContainerNode);
        var $emailError = $('.offers-email-signup-form__errors', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $mobileInput = $('input[name="PHONE2"]', $emailContainerNode);

        $emailForm.once('email-signup__form').submit(function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          $emailInput.removeClass('error');
          $mobileInput.removeClass('error');
          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              $emailError.addClass('hidden');
              $.when(
                $(document).triggerHandler('email_signup.success', [jsonRpcResponse])
              ).then(function () {
              // Only show the success message if the email_signup.success handlers haven't already done so
                if ($('#colorbox').css('display') != 'block') {
                  $.colorbox({ html: $emailSuccess.html() });
                }
              });
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();

              $emailError.removeClass('hidden');
              generic.showErrors(errorObjectsArray, $emailError, $emailForm);
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context, settings) {
      site.emailSignup.templateContainer = $('.email-signup', context);
      site.emailSignup.initEmailSignup();
    }
  };
})(jQuery);
